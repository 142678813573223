<template>
  <DashboardTemplate>
    <div class="pd-x-5">
      <el-row>
        <el-col :span="24">
          <div class="is-flex ai-center js-between">
            <h2>ข้อมูลผู้ใช้งาน</h2>
            <div>
              <a @click="resetDBD()" plain class="mg-r-3">Reset</a>
              <el-tooltip
                class="item"
                effect="dark"
                v-if="lastSync.sync == 0"
                content="มีกำหนดการ Sync ข้อมูลกับ DBD เวลา 18.00 น."
                placement="bottom"
              >
                <span>
                  <el-button
                    type="primary"
                    style="width: 130px"
                    :disabled="true"
                    ><i class="far fa-clock"></i> DBD Sync</el-button
                  >
                </span>
              </el-tooltip>
              <el-button
                v-else
                type="primary"
                style="width: 130px"
                @click="syncDBD()"
                >DBD Sync</el-button
              >
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <el-form ref="filter" :model="filter" label-position="top">
            <div class="bordered rounded pd-2 pd-t-4 pd-b-5 mg-b-3">
              <el-row>
                <el-col :span="8" :offset="0">
                  <el-input
                    v-model="filter.search"
                    placeholder="ค้นหา ชื่อ/นามสกุล/อีเมล"
                    clearable
                    @input="handleCurrentChange(1, 600)"
                  ></el-input>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </el-col>
        <el-col :span="24" :offset="0">
          <div
            class="text-right mg-b-5 text-grey-light font-16"
            v-if="lastSync"
          >
            ข้อมูลจาก DBD อัปเดตล่าสุดเมื่อ
            {{ lastSync.updatedAt | dateTimeTh }}
          </div>
        </el-col>
        <el-col :span="24" :offset="0">
          <el-table
            border
            :data="userList"
            v-loading="isLoading"
            style="width: calc(100% - 5px)"
          >
            <template slot="empty">
              <div class="noData">ไม่พบข้อมูลที่ค้นหา</div>
            </template>

            <el-table-column
              prop="name"
              label="ชื่อ"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="surname"
              label="นามสกุล"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="email"
              label="อีเมล"
              align="center"
              header-align="center"
            >
            </el-table-column>
            <el-table-column
              prop="mobilephone"
              label="หมายเลขโทรศัพท์"
              align="center"
              header-align="center"
              width="180px"
            >
            </el-table-column>
            <el-table-column
              prop="companies"
              label="ข้อมูลบริษัท"
              align="center"
              header-align="center"
              width="120px"
            >
              <template slot-scope="scope">
                {{ scope.row.companies }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createdAt"
              label="สมัครเมื่อ"
              align="center"
              header-align="center"
              width="220px"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.createdAt != 6">
                  {{ moment(scope.row.createdAt).format("lll น.") }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label=""
              align="center"
              header-align="right"
              width="70px"
            >
              <template slot-scope="scope">
                <!-- <span>
                  <i
                    class="fas fa-user-slash text-danger mg-r-7"
                    v-if="scope.row.consent == 1 || scope.row.consent == 3"
                  ></i>
                </span> -->

                <el-dropdown trigger="click" class="mg-r-6">
                  <span class="el-dropdown-link">
                    <i
                      class="
                        fas
                        fa-ellipsis-v
                        mg-l-6
                        color-grey
                        cr-pointer
                        hover-primary
                      "
                    ></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item class="color-danger"
                      ><a
                        :href="`/system/user/profile/${scope.row.userId}`"
                        class="text-grey"
                        target="_blank"
                      >
                        ข้อมูลส่วนตัว
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item class="color-danger"
                      ><a
                        :href="`/system/user/company/${scope.row.userId}`"
                        class="text-grey"
                        target="_blank"
                      >
                        ข้อมูลบริษัท
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item class="color-danger"
                      ><a
                        :href="`/system/user/password/${scope.row.userId}`"
                        class="text-grey"
                        target="_blank"
                      >
                        เปลี่ยนรหัสผ่าน
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item divided class="color-danger"
                      ><div
                        @click="
                          delUser(
                            scope.row.userId,
                            scope.row.name,
                            scope.row.email
                          )
                        "
                      >
                        ลบข้อมูลผู้ใช้
                      </div></el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24">
          <div class="mg-y-3">
            <div class="block text-center">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-sizes="[15, 30, 50, 100, 500]"
                :page-size="15"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalUser"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardAdminTemplate";
import userService from "@/service/userListService";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardTemplate
  },
  mounted() {
    this.fetchUserList();
    this.getLastSync();
  },
  data() {
    return {
      userList: [],
      totalUser: 0,
      filter: {
        search: "",
        limit: 15,
        skip: 0
      },
      currentPage: 1,
      debounce: null,
      isLoading: false,
      lastSync: { id: 0 }
    };
  },
  methods: {
    resetDBD ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`system/admin/update/status/request/dbd`).finally(() => {
        // this.$message({
        //   type: "success",
        //   message: "ข้อมูล DBD จะถูกอัปเดตหลังเวลา 4.00 น. ของวันถัดไป"
        // });
      });
      setTimeout(() => {
        this.getLastSync();
      }, 800);
    },
    syncDBD ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`/12QivZmuHdyulOlSH9Re`).finally(() => {
        this.$message({
          type: "success",
          message: "ข้อมูล DBD จะถูกอัปเดตหลังเวลา 18.00 น."
        });
      });
      setTimeout(() => {
        this.getLastSync();
      }, 800);
    },
    getLastSync ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`/last/sync`)
        .then(res => {
          if (res.data.success) {
            this.lastSync = res.data.obj;
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
    },
    delUser(id, name, email) {
      // this.loading = true;
      this.$confirm(
        "หากยืนยันข้อมูลของ " +
          name +
          " (" +
          email +
          ")" +
          " จะลบออกจากระบบถาวร",
        "ยืนยันการลบข้อมูล",
        {
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          type: "warning"
        }
      )
        .then(() => {
          this.loading = true;
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.delete(`user/${id}`)
            .then(res => {
              if (res.data.success) {
                this.$message({
                  type: "success",
                  message: "ลบข้อมูลสำเร็จ"
                });
                this.handleCurrentChange(this.currentPage);
              } else {
                this.alertFailError();
                this.loading = false;
              }
            })
            .catch(e => {
              if (e == "Error: Request failed with status code 401") {
                this.checkAuth();
              } else {
                if (e != "Error: timeout of 120000ms exceeded") {
                  this.alertCatchError(e);
                }
              }
              this.loading = false;
              console.log("delUser error", e);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "ยกเลิกการลบ"
          });
          this.loading = false;
        });
    },
    syncUserDbd ( id )
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`system/admin/request/dbd/juristicId`, { userId: id })
        .then(result => {
          if (result.data.success) {
            if (result.data.dbd) {
              this.$message({
                message: "Sync ข้อมูลสำเร็จ",
                type: "success"
              });
            } else {
              this.$message.error({
                message: "เลขนิติบุคคลนี้ไม่มีข้อมูลตาม DBD"
              });
            }
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
    },
    handleSizeChange(val) {
      this.filter.limit = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val, time = 0) {
      this.isLoading = true;
      this.currentPage = val;
      let end = this.currentPage * this.filter.limit;
      let start = (this.currentPage - 1) * this.filter.limit;
      this.filter.skip = start;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.fetchUserList();
      }, time);
    },
    async fetchUserList() {
      this.isLoading = true;
      let obj = {
        ...this.filter
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.post(`system/admin/all/users`, obj).catch(e => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      if (res.data.success) {
        this.userList = res.data.obj;
        this.totalUser = res.data.total;
      } else {
      }
      this.isLoading = false;
    }
  }
};
</script>